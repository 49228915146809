<template>
  <div>
    <div
      class="breadcrumb-area rn-bg-color ptb--60 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div
              class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50 text-white"
            >
              <h2 class="heading-title">Mes amis</h2>
              {{ nbNotifications }} notification(s) envoyée(s) pendant le mois.
              <v-row justify="center">
                <AddContact
                  @inserted="getContacts"
                  v-bind:dialogStatus.sync="addContact"
                ></AddContact>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start team Area  -->
    <div class="rn-team-area bg_color--1 ptb--60" style="min-height: 400px">
      <v-container v-show="isLoading">
        <div class="text-center">
          <div variant="primary" label="Text Centered"></div>
        </div>
      </v-container>

      <v-container v-show="!isLoading">
        <v-row>
          <v-col lg="12">
            <v-alert
              color="pink"
              dark
              border="top"
              icon="mdi-alert"
              transition="scale-transition"
              v-if="list.filter(item => item.unsubscribe).length > 0"
            >
              Attention, certains de vos amis ne souhaitent pas faire partie de
              vos amis de confiance. Nous ne leur enverrons plus de demande
              d'assistance.<br />
              Vous pouvez les supprimer de votre espace.
            </v-alert>
          </v-col>

          <v-col lg="12">
            <div v-if="list.length == 0">
              Vous n'avez pas encore ajouté d'ami.
            </div>

            <div class="d-flex justify-center" v-if="list.length > 0">
              <table
                class="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_1"
              >
                <thead>
                  <tr class="text-left">
                    <th style="width: 30px"></th>
                    <th style="min-width: 200px">Nom</th>
                    <th style="min-width: 150px">télèphone</th>
                    <th style="min-width: 150px">portable</th>
                    <th style="min-width: 150px">mail</th>
                    <th class="pr-0 text-right" style="min-width: 150px">
                      action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="isLoading">
                    <td colspan="7">
                      <div class="text-center">
                        <div variant="primary" label="Text Centered"></div>
                      </div>
                    </td>
                  </tr>

                  <template v-for="(item, i) in list">
                    <tr v-bind:key="i">
                      <td>
                        {{ i + 1 }}
                      </td>
                      <td class="pr-0">
                        <div class="symbol symbol-50 symbol-light mt-1">
                          {{ item.name }}
                        </div>
                      </td>
                      <td class="pl-0">
                        {{ item.phone }}
                      </td>
                      <td class="pl-0">
                        {{ item.mobile }}
                      </td>
                      <td>
                        {{ item.email }}
                      </td>

                      <td class="pr-0 text-right">
                        <v-icon
                          color="red darken-2"
                          v-if="item.unsubscribe == true"
                          >mdi-alert</v-icon
                        >

                        <v-btn
                          style="border: 0"
                          icon
                          color="primary"
                          :loading="isDeleting[i]"
                          :disabled="isDeleting[i]"
                          v-on:click="removeContact(item, i)"
                        >
                          <v-icon>mdi-delete</v-icon>
                          <template v-slot:isDeleting[i]>
                            <span class="custom-loader">
                              <v-icon light>mdi-cached</v-icon>
                            </span>
                          </template>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" class="slide-btn">
          <button
            class="btn-default col-6 col-lg-2 m-5 p-3"
            @click="addContact = true"
            v-if="canAdd"
          >
            Ajouter un ami
          </button>
          <v-btn
            type="button"
            class="btn-custom"
            @click="router.push('/user/subscription')"
            v-if="!canAdd && nbContact < 5"
          >
            Changer le forfait
          </v-btn>
        </v-row>
        <!-- End row -->
      </v-container>
    </div>
    <!-- Start team Area  -->
  </div>
</template>
<style>
.btn:focus {
  outline: none;
  box-shadow: none;
  background: var(--color-primary);
  border-color: var(--color-primary);
}
</style>
<script>
import API from "@aws-amplify/api";
import * as queries from "@/graphql/queries";
import * as manageContact from "@/graphql/mutations";
import AddContact from "@/views2/user/widget/addContact.vue";
import Swal from "sweetalert2";

export default {
  components: {
    AddContact
  },
  mounted() {
    this.getNotifications();
    this.getContacts();
  },
  model: {
    event: "onNbContactUpdate"
  },
  data() {
    return {
      router: this.$router,
      list: [],
      isLoading: true,
      isDeleting: [false, false, false, false, false],
      addContact: false,
      nbContact: null,
      canAdd: false,
      nbNotifications: 0,
      subscription: this.$store.state.auth.user.attributes[
        "custom:subscription"
      ],

      breadcrumbs: [
        {
          text: "Accueil",
          to: "/",
          disabled: false
        },
        {
          text: "Mes amis",
          to: "",
          disabled: true
        }
      ]
    };
  },
  methods: {
    onNbContactUpdate(value) {
      let availableSlot = 0;
      switch (this.subscription) {
        case "FREE":
          availableSlot = 1 - parseInt(value);
          break;
        case "PLAN1":
        case "PLAN2":
          availableSlot = 5 - parseInt(value);
          break;
        default:
          availableSlot = 1 - parseInt(value);
          break;
      }
      this.canAdd = availableSlot > 0;
    },
    async getContacts() {
      //this.isLoading = true;
      API.graphql({ query: queries.listCONTACTS })
        .then(friends => {
          this.list = friends.data.listCONTACTS.items;
          this.nbContact = this.list.length;
          this.onNbContactUpdate(this.nbContact);
        })
        .catch(err => {
          Swal.fire({
            title: "Please, retry later",
            text: err.message,
            icon: "error",
            heightAuto: false
          });
        })
        .finally(() => (this.isLoading = false));
    },
    async getNotifications() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

      API.graphql({
        query: queries.listNOTIFICATIONS,
        variables: {
          filter: {
            created: {
              gt: firstDay.toISOString()
            }
          },
          limit: 50
        }
      })
        .then(notifications => {
          this.nbNotifications =
            notifications.data.listNOTIFICATIONS.items.length;
        })
        .catch(err => {
          Swal.fire({
            title: "Please, retry later",
            text: err.message,
            icon: "error",
            heightAuto: false
          });
        });
    },
    async removeContact(item, idx) {
      this.isDeleting[idx] = true;
      return API.graphql({
        query: manageContact.deleteCONTACT,
        variables: {
          input: {
            id: item.id
          }
        }
      })
        .then((/*res*/) => {
          this.getContacts();
          this.$gtm.trackEvent({
            category: "Friends",
            action: "Update",
            label: "action",
            value: "remove"
          });
        })
        .catch(err => {
          Swal.fire({
            title: "Please, retry later",
            text: err.message,
            icon: "error",
            heightAuto: false
          });
        })
        .finally(() => (this.isDeleting[idx] = false));
    }
  }
};
</script>
